import React from "react"
import { Flex, Heading, Text, VStack } from "@chakra-ui/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const PostCard = ({
  frontmatter: { title, categories, description, thumbnail, slug },
  excerpt,
}) => {
  const image = getImage(thumbnail)
  return (
    <Link to={"/post/" + slug}>
      <Flex flexDirection="column" bg="white" mb="30px">
        <GatsbyImage image={image} alt={title} />
        <VStack paddingX="24px" paddingY="20px" alignItems="flex-start">
          <Text
            fontSize="xs"
            fontWeight="bold"
            color="brandGreen"
            textTransform="uppercase"
          >
            {categories[0]}
          </Text>
          <Heading fontSize="xl">{title}</Heading>
          <Text>{description}</Text>
        </VStack>
      </Flex>
    </Link>
  )
}

export default PostCard
