import React from "react"
import { Flex, Heading, Link, VStack } from "@chakra-ui/react"
import { Link as GatsbyLink} from "gatsby"

const PostCard = () => {
  return (
      <Flex flexDirection="column" bg="white" mb="30px">
        <VStack paddingX="24px" paddingY="20px" alignItems="flex-start" spacing="20px">
          <Heading fontSize="xl">Download Weld Desktop App for free</Heading>
          <Link
          as={GatsbyLink}
          to="/"
          fontWeight="medium"
          fontSize="14px"
          color="white"
          bg="brandGreen"
          px="32px"
          py="14px"
          w="full"
          textAlign="center"
          borderRadius="3px"
        >
          Download
        </Link>
        </VStack>
      </Flex>
  )
}

export default PostCard
