import React from "react"
import Masonry from "react-masonry-css"

import PostCard from "./PostCard"
import DownloadCard from './DownloadCard'


const PostCards = ({ posts, showDownload }) => {
  return (
    <Masonry
      className="masonry-grid"
      columnClassName="masonry-grid_column"
      breakpointCols={{
        default: 3,
        1100: 3,
        700: 2,
        500: 1,
      }}
    >
      {posts.map((post, index) => (
        <>
          <PostCard key={index} {...post} />
          {(showDownload && index === posts.length / 2 ) && <DownloadCard/>}
        </>
      ))}
    </Masonry>
  )
}

export default PostCards
