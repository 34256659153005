import * as React from "react"
import { Container } from "@chakra-ui/react"
import Layout from "../components/Layout"
import Categories from "../components/Home/Categories"
import PostsContainer from "../components/Home/PostCards"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const CategoryTemplate = ({data,pageContext:{categories}}) => {
  const {allPosts} = data

  const formattedPost = allPosts.edges.map( post => post.node)
  return (
    <Layout>
      <Seo title="Home" />
      <Container maxW={"container.xl"}>
      <Categories categories={categories}/>
      <PostsContainer posts={formattedPost} />
      </Container>
    </Layout>
  )
}

export default CategoryTemplate

export const query = graphql`
   query PostsByCategory($category: String!) {
    allPosts:allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          categories: { eq: $category }
          published: { ne: false }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            description
            categories
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 362
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          timeToRead
        }
      }
    }
  }
`
